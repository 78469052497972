import React, { useEffect } from "react"
import { ImageDataLike } from "gatsby-plugin-image"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import { Img } from "./Img"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../../helpers/WatcherDL"

type BannerProps = {
  large: ImageDataLike
  mob: ImageDataLike
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
  classes?: BannerClasses
  variantStyle?: "mebelor"
}

type BannerClasses = {
  root?: string
  container?: string
  secondContainer?: string
  img?: string
  textContainer?: string
  heading?: string
  description?: string
  button?: string
  buttonBlock?: string
  buttonMob?: string
}

const defaultTitle = `
  <span>Карта «Халва»</span>
  <br/>
  24 месяца без % на&nbsp;все!
`

const defaultSubtext = `
  — До 17% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />— Обслуживание 0 ₽
`

export function Banner({
  large,
  mob,
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultSubtext,
  orderNum,
  classes,
  variantStyle,
}: BannerProps) {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])

  return (
    <>
      <section
        className={clsx(styles.section, classes?.root, variantStyle && styles[variantStyle])}
        data-exclude={orderNum}
      >
        <Container className={clsx(styles.container, classes?.container)}>
          <div className={clsx(styles.secondContainer, classes?.secondContainer)}>
            <Img
              alt="bg image"
              className={clsx(styles.img, classes?.img)}
              large={large}
              mob={mob}
            />
            <div className={clsx(styles.text, classes?.textContainer)}>
              <h1 className={clsx(classes?.heading)} dangerouslySetInnerHTML={{ __html: title }} />
              <div
                className={clsx(classes?.description)}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <Button
              className={clsx(styles.btn, classes?.button)}
              onClick={() => handleClickBanner("click_cta")}
            >
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, classes?.buttonBlock)}>
        <Container>
          <Button
            onClick={() => handleClickBanner("click_cta")}
            className={clsx(styles.mobBtn, classes?.buttonMob)}
          >
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
